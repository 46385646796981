<template>
  <v-flex xs12 sm8 md4>
    <v-card class="elevation-12">
      <v-card-text>
        <v-form ref="login" v-model="valid" lazy-validation>
          <v-text-field
            id="passwod"
            label="Пароль"
            prepend-icon="mdi-lock"
            v-model="password"
            type="password"
            :rules="passwordRules"
            required
          ></v-text-field>
          <v-text-field
            id="verify"
            label="Повтор пароля"
            prepend-icon="mdi-lock"
            v-model="verify"
            type="password"
            :rules="[passwordMatch, passwordRules]"
            required
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="setPass({ password })"
          :disabled="!passwordMatchBtn"
          block
          >Задать пароль</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-alert v-if="alert.show" :type="alert.type" dense lazy>{{
      alert.text
    }}</v-alert>
  </v-flex>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      alert: {
        show: false,
        type: "info",
        text: "",
      },
      restorebuttonActive: true,
      password: "",
      verify: "",
      passwordRules: [
        (v) => !!v || "Обязательное для заполнения",
        (v) =>
          (v && v.length >= 6) || "Пароль должен содержать минимум 6 символов",
      ],
      message: "",
      status: false,
    };
  },
  computed: {
    // ...mapGetters(["user"]),
    passwordMatch() {
      return () => this.password === this.verify || "Пароли должны совпадать";
    },
    passwordMatchBtn() {
      return (
        this.password &&
        this.password === this.verify &&
        this.restorebuttonActive
      );
    },
  },
  methods: {
    ...mapActions(["setNewPassword"]),
    async setPass(password) {
      const resp = await this.setNewPassword(password);
      this.alert.show = true;
      this.alert.text = resp.data.message;
      this.alert.type = resp.data.status ? "info" : "error";
      this.restorebuttonActive = false;
      console.log(resp.data.message);
      setTimeout(() => {
        this.alert.show = false;
        this.restorebuttonActive = true;
        if (resp.data.status) this.$router.push("/monitoring");
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>