<template>
  <v-flex xs12 sm8 md4>
    <v-card class="elevation-12">
      <v-tabs model="tabs" grow>
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab v-for="i in tabs" :key="i.name">
          <v-icon left>{{ i.icon }}</v-icon>
          {{ i.name }}
        </v-tab>
        <v-tab-item>
          <v-card-text>
            <v-form ref="login" v-model="valid" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                label="Телефон"
                v-model="phone"
                :rules="phoneRules"
                counter="11"
                required
                autofocus
                maxlength="11"
              ></v-text-field>
              <v-text-field
                id="passwod"
                label="Пароль"
                prepend-icon="mdi-lock"
                v-model="password"
                type="password"
                :rules="passwordRules"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-checkbox
              v-model="checkbox"
              :rules="[
                (v) => !!v || 'Согласен с уловиями политики конфиденциальности',
              ]"
              label="Принимаю условия"
              required
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              @click="loginUser({ phone, password })"
              :disabled="!phone || !password || !checkbox"
              >Войти</v-btn
            >
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-form ref="restore" v-model="valid" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                label="Телефон"
                v-model="phone"
                :rules="phoneRules"
                counter="11"
                required
                autofocus
                maxlength="11"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="resorePass(phone)"
              :disabled="!phone || !restorebuttonActive"
              block
              >Отправить ссылку на задание пароля</v-btn
            >
          </v-card-actions>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-alert v-if="alert.show" :type="alert.type" dense lazy>{{
      alert.text
    }}</v-alert>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      alert: {
        show: false,
        type: "info",
        text: "all good",
      },
      restorebuttonActive: true,
      tab: 0,
      tabs: [
        { name: "Авторизация", icon: "mdi-key" },
        { name: "Задать пароль", icon: "mdi-restore" },
      ],
      valid: true,
      phone: "",
      phoneRules: [
        (v) => !!v || "Обязательное для заполнения",
        (v) =>
          (v && v.replace(/\D+/g, "").length == 11 && v[0] == 7) ||
          "Телефон должен содержать 11 цифр начиная с 7",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Обязательное для заполнения",
        (v) =>
          (v && v.length >= 6) || "Пароль должен содержать минимум 6 символов",
      ],
      checkbox: false,
      message: "",
      status: false,
      showPopUp: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["restorePassword", "loginUser"]),
    async resorePass(phone) {
      const resp = await this.restorePassword(phone);
      this.alert.show = true;
      this.alert.text = resp.data.message;
      this.alert.type = resp.data.status ? "info" : "error";
      this.restorebuttonActive = false;
      console.log(resp.data.message);
      setTimeout(() => {
        this.alert.show = false;
        this.restorebuttonActive = true;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>