<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <AuthForm v-if="!restore" />
      <RestoreForm v-if="restore" />
    </v-layout>
  </v-container>
</template>

<script>
import AuthForm from "@/components/AuthForm";
import RestoreForm from "@/components/RestoreForm";

export default {
  data() {
    return {
      restore: false,
    };
  },
  components: {
    AuthForm,
    RestoreForm,
  },
  created() {
    if ("token" in this.$route.query) this.restore = true;
  },
};
</script>